import React, { useEffect, useState } from "react";
import {parsePath, useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
// import {saveAs} from 'file-saver';
import * as Upload from "upload-js-full";
import download from 'downloadjs';
import faviconUrl from '../../assets/weberwelt_logo.ico'
import faviconIco from '../../assets/favicon.ico'


const fileApi = new Upload.FileApi(
    new Upload.Configuration({
      apiKey: "public_W142hVXxg6aWu6EYP4dUV6ASPJNX"
    })
  );

const useStyles = makeStyles(() => ({
    imageContainer: {
        width: '90%',
        textAlign: 'center',
        marginLeft: '5%',
    },
    image: {
        width: '30%',
        padding: '1vw',
        '@media (max-width: 780px)': {
            width: '80%',
            padding: '1vw',
          }
    },
    title: {
        marginLeft: "5%",
        overflowWrap: 'break-word',
        '@media (max-width: 780px)': {
            fontSize: '5vw',
          }

    },
    button: {
        marginLeft: "5%"
    }
    }));

function Pictures() {
    const [images, setImages] = React.useState([]);
    const [complaintImages, setComplaintImages] = React.useState([]);
    const [isLargeWatermark, setIsLargeWatermark] = React.useState(false);
    const classes = useStyles();
    let params = useParams();
    let service = params.service;
    let storm = params.storm;
    let addr = params.address.replaceAll("_"," ");

    useEffect(() => {
        document.title = "Weberwelt Proof of Service";
        const setFavicon = (faviconUrl) => {
            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
              favicon.href = faviconUrl;
            }
          };
      
        setFavicon(faviconUrl);

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Basic YXBpa2V5OnB1YmxpY19XMTQyaFZYeGc2YVd1NkVZUDRkVVY2QVNQSk5Y");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        let address = params.address.replaceAll("_","");
        address = params.address.replaceAll(" ","");
        var path = `/uploads/${storm}/${service}/properties/${address}`
        fetch(`https://api.upload.io/v2/accounts/W142hVX/folders/children?folderPath=${path}`, requestOptions)
        .then(response => response.json().then((data) => {
            console.log("imagedata",data)
            if (data != undefined) {
                if (data.children != undefined && data.children[0] != undefined) {
                    console.log(data.children);
                    setImages(data.children);
                }
            }
        }))
        .catch(error => console.log('error', error));

        let complaintPath = `${path}/Complaint`;
        fetch(`https://api.upload.io/v2/accounts/W142hVX/folders/children?folderPath=${complaintPath}`, requestOptions)
        .then(response => response.json().then((data) => {
            console.log("complaintDate",data)
            if (data != undefined) {
                if (data.children != undefined && data.children[0] != undefined) {
                    console.log('complaint images', data.children);
                    setComplaintImages(data.children);
                }
            }
        }))
        .catch(error => console.log('error', error));

        return () => {
            // Reset the favicon to the default one
            setFavicon(faviconIco);
          };
    }, []);

    const download = (fileUrl, filename) => {
        var myHeaders = new Headers();
        // myHeaders.append("Authorization", "Basic YXBpa2V5OnB1YmxpY19XMTQyaFZYeGc2YVd1NkVZUDRkVVY2QVNQSk5Y");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        // redirect: 'follow',
        };

        fetch(`${fileUrl}`, requestOptions)
        .then(response => response.blob())
        .then((blob) => {const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = `${filename}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);})
        .catch(error => console.log('error', error));
    };

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };

    const downloadFiles = async () => {
        for (let i = 0; i < images.length; i++) {
            let filename = `${addr}_${i+1}.jpg`
            let url = getWatermarkedImage(images[i].fileUrl);
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
          
            xhr.onload = function() {
              if (xhr.status === 200) {
                const blob = new Blob([xhr.response], { type: 'image/jpeg' }); // Adjust the MIME type based on your image format
                const url = URL.createObjectURL(blob);
          
                const link = document.createElement('a');
                link.href = url;
                link.download = filename; // Specify the desired file name
                link.target = '_blank';
                link.style.display = 'none';
          
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
          
                URL.revokeObjectURL(url);
              }
            };
          
            xhr.send();
        }
    }

    const hasImages = () => {
        if (images.length > 0) {
            return true;
        }
        return false;
    }

    const hasComplaint = () => {
        if (complaintImages.length > 0) {
            return true;
        }
        return false;
    }

    const titleToHeader1 = (title) => {
        let split = title.split(",");

        let address = split[0];
        let numIndex
        let header1 = `${address}`;
        return header1;
    }

    const titleToHeader2 = (title) => {
        let split = title.split(",");

        let town = split[1];
        let state = split[2];
        let zip = split[3];
        let header2 = `${town}, ${state}, ${zip}`;
        return header2;
    }

    // Toggle the watermark mode
  const toggleWatermarkMode = () => {
    setIsLargeWatermark((prev) => !prev);
  };

    const getWatermarkedImage = (file) => {
        console.log('watermark file', file);
        let slashes = file.split("/");
        let underscores = slashes[slashes.length - 1].split("_");
        // let text = `${storm}`;
        let text = "";
        if (underscores.length == 6) {
            let day = parseInt(underscores[0]);
            let month = parseInt(underscores[1]);
            let year = parseInt(underscores[2]);
            let hour = parseInt(underscores[3]);
            let minute = parseInt(underscores[4]);
            console.log(day, month, year, hour, minute)
            let d = new Date(Date.UTC(year, month-1, day, hour, minute, 0,0));
            // let diff = d.getTimezoneOffset();
            // let offset = diff / 60;
            // let hour = (parseInt(underscores[0]) - offset) %24;

            let options = {
                timeZone: 'America/New_York',
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              };

            let estDateString = d.toLocaleString('en-US', options);

            var watermark1 = `image=/uploads/templates/blank_bar.png&gravity=bottom-left&layer-opacity=75`;
            var watermark2large = `text=${estDateString}&blend=source-atop&color=FF5F1F&font-size=1000&layer-opacity=100&font-weight=900&gravity=bottom-left&padding=25`;
            var watermark2small = `text=${estDateString}&blend=source-atop&color=FF5F1F&font-size=200&layer-opacity=100&font-weight=900&gravity=bottom-left&padding=`;
            let path = `${file.replace('/raw/','/image/')}?${watermark1}&${isLargeWatermark ? watermark2large : watermark2small}`;
            return path;
        }
        
        return file;
    }

    return (
        <>
        <h1 className={classes.title} style={{marginBottom: '0px', marginTop: '30px',}}>{titleToHeader1(addr)}</h1>
        <h1 className={classes.title} style={{marginTop: '0px'}}>{titleToHeader2(addr)}</h1>
        {/* <h2 className={classes.title}>Date: {storm}</h2> */}
        {!hasImages() && (<h2 className={classes.title}>No pictures found for this address and event</h2>)}
        {hasImages() && (<button className={classes.button} onClick={downloadFiles}>Download Images</button>)}
        
        <label>
        <input
          type="checkbox"
          checked={isLargeWatermark}
          onChange={toggleWatermarkMode}
        />
        {isLargeWatermark ? "Large Watermark" : "Small Watermark"}
      </label>

        <div className={classes.imageContainer}>
            {images.map((image) => {
                if (image.fileUrl) {
                    return(<img className={classes.image} src={getWatermarkedImage(image.fileUrl)} />)
                }
                else {
                    return <></>
                }
            })}
        </div>
        {hasComplaint() && (<h2 className={classes.title}>After Complaint:</h2>)}
        <div className={classes.imageContainer}>
            {complaintImages.map((image) => {
                return(<img className={classes.image} src={image.fileUrl} />)
            })}
        </div>
        </>
    )
}

export default Pictures